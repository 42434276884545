.my-preview-container {
  margin-bottom: 1em;
  padding-left: 1em;
  padding-right: 1em;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  height: 220px;
  top: 50px;
  left: 0;
  width: 100%;
}

.sv-action-bar {
  display: none !important;
}

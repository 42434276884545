:root {
  --animation-color: #6484ec;
}

.priceCard {
  position: relative;
  overflow: hidden;
}
.ripple {
  position: absolute;
  left: 0;
  top: 0;
  background: var(--animation-color);
  opacity: 0.3;
  transform: translate(-50%, -50%);
  pointer-events: none;
  border-radius: 50%;
  animation: animate 0.3s linear normal;
}

@keyframes animate {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 500px;
    height: 500px;
  }
}
.error-message {
  color: red;
  font-size: 14px;
  font-family: Inter;
  margin-left: 5px;
  margin-top: 2px;
}
.loginForm .MuiInputBase-input {
  color: #212121;
}
#user-popover .MuiPaper-root {
  border-radius: 12px;
}

.stere-chat-navbar {
  padding: 20px 10px !important;
}
:root {
  --primary: #002e5b;
  --muted: #757575;
  --sjs-primary-backcolor-dark: var(--primary);
  --font-family: "Inter";
  --input-label-color: #757575;
  --input-box-bg-color: #fafafa;
  --input-text-color: black;
  --input-focus-border-color: rgba(0, 0, 0, 0.5);
  --input-border-color: rgba(0, 0, 0, 0.5);
  --input-hover-border-color: rgba(0, 0, 0, 0.5);
  --input-checkbox-border-color: (0, 0, 0, 0.5);
  --form-card-border-color: #80808040;
}

.sd-input--disabled,
.sd-input--disabled::placeholder {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.3;
}

.sd-body.sd-body--static {
  margin-left: 0;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sd-root-modern--mobile .sd-body.sd-body--static {
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.sd-dropdown__filter-string-input {
  color: var(--input-text-color) !important;
}
.sv-list__item .sv-list__item--selected .sd-list__item--selected {
  background-color: white !important;
  color: black !important;
}
.sv-list__item-body,
.sd-list__item-body {
  background-color: white !important;
  color: black !important;
}
.sv-list__item-body:hover,
.sd-list__item-body:hover {
  background-color: var(--input-focus-border-color) !important;
  color: white !important;
}
.sd-dropdown__value {
  color: var(--input-text-color) !important;
}
.sd-row {
  margin-top: 15px !important;
}
.sd-panel__footer {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.sd-dropdown:focus-within {
  border: 2px solid;
  border-color: var(--input-focus-border-color);
  box-shadow: none !important;
  border-radius: 12px;
}

.sd-dropdown {
  border: 1px solid var(--input-border-color);
}

.sd-selectbase {
  border: none;
}
.sd-panel__content {
  margin-top: 10px;
  border: solid thin;
  border-color: var(--form-card-border-color);
  border-radius: 12px;
  padding: 20px !important;
}
.sd-paneldynamic__separator {
  display: none;
}
.sd-element--nested,
.sd-panel__footer {
  border: none !important;
}

.sd-paneldynamic__placeholder {
  border: solid thin;
  border-radius: 12px;
  border-color: var(--form-card-border-color);
}

.sd-element__title .sv-string-viewer,
.sd-paneldynamic__remove-btn {
  white-space: normal;
  font-style: normal;
  font-weight: 500;
  color: var(--input-label-color);
  font-size: 14px;
  line-height: 20px;
}
.sd-paneldynamic__remove-btn {
  color: #e5484d;
  border: solid thin #e5484d;
  border-radius: 4px;
  margin-top: 10px;
}
.sd-question__header--location-top {
  padding-bottom: 12px;
}

.sd-question__required-text {
  color: var(--input-label-color) !important;
}

.svc-logic-question-value,
.sd-element--with-frame {
  border: none;
  background: none;
  padding: 0 0 15px 0;
  box-shadow: none;
}

.sv-components-row {
  background: transparent;
}

.sv-components-row > .sv-components-column--expandable {
  width: inherit;
}

.svc-logic-question-value > .sd-question__erbox--above-question,
.sd-element--with-frame > .sd-question__erbox--above-question,
.svc-logic-question-value > .sd-question__erbox--outside-question,
.sd-element--with-frame > .sd-question__erbox--outside-question,
.sd-question__erbox--outside-question,
.sd-element--with-frame > .sd-element__erbox {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin: 0;
  color: #e5484d;
  padding: 0;
  background: none;
  width: 100%;
}

.sd-btn {
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  border-radius: 18px;
  color: #ffffff;
  background-color: var(--primary);
}

.sd-btn:hover,
.sd-btn:not(:disabled):hover {
  color: #ffffff;
  background-color: var(--primary);
  border: 2px solid #eb2550;
}

.sd-root-modern {
  background: transparent;
}

.sv-tagbox__item {
  height: 100%;
}

.sd-tagbox_clean-button {
  padding: 0 8px;
  margin: auto 0;
  height: 100%;
}

.sd-tagbox-item_clean-button {
  background: linear-gradient(
    270deg,
    var(--primary),
    53.12%,
    rgba(0, 46, 91, 0) 100%
  );
}

.sd-action:not(.sd-action--pressed):hover,
.sd-action:not(.sd-action--pressed):focus {
  background-color: rgba(0, 46, 91, 0.1);
}

.sd-page .sd-page__title {
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: #706f6f;
  margin: 0 auto 50px auto;
}

.sd-body__navigation.sd-action-bar {
  justify-content: center;
}

.sd-input {
  box-shadow: none;
  border-radius: 12px;
  border: 2px solid;
  border-color: transparent;
  border-color: var(--input-border-color);
  background-color: var(--input-box-bg-color);
  color: var(--input-text-color);
  transition: all 0.3s;
  text-align: left !important;
}

.sd-input.sd-input:hover {
  border-color: var(--input-hover-border-color);
}

.sd-input.sd-input:focus {
  box-shadow: none;
  border-radius: 12px;
  border: 2px solid;
  border-color: var(--input-focus-border-color);
}

.sd-question__header {
  text-align: left;
}

.sd-paneldynamic__header .sd-element__title .sv-string-viewer,
.sd-element--complex.sd-element--with-frame
  > .sd-element__header--location-top {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 37px;
}

.sd-paneldynamic__footer {
  display: none;
}

.sd-root-modern,
.svc-logic-question-value {
  --sd-base-padding: 4px;
}

.sd-input--error {
  background-color: var(--input-box-bg-color);
}

.sd-root-modern.sd-root-modern--full-container {
  overflow: visible;
  height: auto;
}

.sd-element--with-frame.sd-question--title-top {
  padding-top: 0px;
}

.sd-root-modern--mobile .sd-body.sd-body--static {
  padding: 0 !important;
}

.sd-page {
  padding: 0;
}

.sd-container-modern {
  margin-bottom: 0;
}

.sd-paneldynamic__header {
  display: none;
}

.sd-paneldynamic__panel-footer {
  padding-bottom: 20px;
  margin-right: 0;
}

.sd-radio__decorator {
  cursor: pointer;
  border: 1px solid var(--input-border-color);
}

.sd-item__control-label .sv-string-viewer {
  color: var(--input-text-color) !important;
}

.sd-item--checked .sd-item__decorator {
  background: var(--input-focus-border-color) !important;
  border: none;
}

.sd-radio--checked .sd-radio__decorator:after {
  background-color: white !important;
}

.sd-checkbox__decorator {
  border: 1px solid var(--input-checkbox-border-color) !important;
}

.sv-dropdown-popup {
  height: 100% !important;
}

.sv-string-viewer.sv-string-viewer--multiline {
  white-space: normal !important;
}
.chatBot {
    background: rgb(245, 248, 251);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 10px 12px 24px 0px;
    overflow: hidden;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 350px;
    z-index: 999;
    transform-origin: right bottom;
    transition: transform 0.3s ease 0s;
    border: 1px solid #0003;
  }

  .chatBot > .rsc-container {
    height: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .showChat {
    transform: scale(1);
  }
  
  .hideChat {
    transform: scale(0);
  }
  
  .chatBot * {
    font-family: Poppins;
  }
  
  .chatBot .rsc-ts-user .rsc-ts-bubble {
    word-break: break-word;
  }
  
  .rce-navbar.dark {
    background: var(--default-background) !important;
  }

  .rce-mbox-forward-left, .rce-mbox-forward-right {
    display: none !important;
  }

  .rce-mbox-body {
    max-width: 250px !important;
  }

  .rce-container-input {
    border-top: 1px solid #0003;
  }
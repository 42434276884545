:root {
  --loader-primary-color: #163B61;
  --loader-background-color: #ffffff;
}

div[Widgetloading] {
    background: rgba(0,0,0, 0.1) !important;
  }
  
  div[Widgetloading]>div {
      visibility: visible !important;
      border: 4px solid #fff !important;
      border-color: var(--loader-primary-color) !important;
      border-top:4px solid var(--loader-background-color) !important;
      width: 40px !important;
      height: 40px !important;
      animation: spin 1s linear infinite !important;
      background: none !important;
      position: absolute;
      top: 45% !important;
      left: 47% !important;
     
    }
  